
import RichTextEditor from 'react-rte';
import React from 'react';
import { useEffect } from 'react';

let isData =  {};
export function BodyTextEditor({ fieldName, value, setValue }) {


  const [editorValue, setEditorValue] =
    React.useState(RichTextEditor.createValueFromString("", 'html'));

console.log("editorValue",editorValue)

    

useEffect(() => {
  const currentValue = editorValue.toString('html'); // Get current HTML string from editor
  if (value !== undefined && value !== null && value !== currentValue) {
    setEditorValue(RichTextEditor.createValueFromString(value, 'html'));
  }
}, [value]);


 
 const handleEditChange=value=>{

  isData[fieldName] = true;
  setEditorValue(value);
 
  setValue(value.toString("html"));
   // setValue(editorValue.toString("html"));
 }


  return (<>
    <RichTextEditor
        value={editorValue}
        onChange={handleEditChange}
        required
        id="body-text"
        name="bodyText"
        type="string"
        multiline
        variant="filled"
        style={{ minHeight: 410 }}
      />
       
            </>
  );
}