import React, { useState, useEffect } from "react";
import API from "../../../utils/API"
import { Card } from 'react-bootstrap';
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BodyTextEditor } from "../../../components/RichEdittorComp";
import ProductSection from "./ProductSection";
import Select from "react-select";
import CollectionBanner from "./CollectionBanner";
const Collection = (props) => {
  const [superCollection,setSuperCollection] = useState([]);
  const [collection,setCollection] = useState({});
  const [imageurl,setImageUrl] = useState()
  const [collectionStatus, setCollectionStatus] = useState("Active");
  const [title,setTitle] = useState("")
  const [superCollectionId,setSuperCollectionId] = useState()
  const [error,setError]  = useState({})
  const [products,setProducts] = useState([])
  const [titleArabic,setTitleArabic] = useState("");
  const [seoUrl,setSeoUrl] = useState("");
  const [countries,setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({ value: 'bahrain', label: 'Bahrain',id:8});
  const [countryWiseDetails, setCountryWiseDetails] = useState([
    {
      countryId: 8,
      description:"",
      descriptionArabic: ""
    }
  ]);
  const [collectionBanners, setCollectioBanners] = useState([])

  const params = useParams();
  const collectionId = params.id || "";
  const BASE_URL_COUNTRIES = "/api/v1/active/countries";

  useEffect(()=>{
    const fetchCountries =async ()=>
    {
    try{
      const res = await API.get(BASE_URL_COUNTRIES);
     
      const data = res.data;
      setCountries(data.map((ctry)=>{return({label:ctry.name, value:ctry.name,id:ctry.id})}))
      }
      catch{
        console.log("fetching the countries")
      }
    }
    fetchCountries();
  },[])

  const getCollection = async()=>{
    try{
      const res =  await API.get(`/api/v1/private/collection/${collectionId}`);
      const collection = res.data || {}
      setCollection(collection)
      setTitle(collection.title)
      setTitleArabic(collection.titleArabic)
      setCollectionStatus(collection.status ? "Active": "Inactive")
      setSuperCollectionId(collection?.superCollectionId)
      setImageUrl(collection?.image)
      setProducts(collection?.ranks)
      setSeoUrl(collection?.seoUrl)
      setCountryWiseDetails(collection?.countryWiseDetails)
      setCollectioBanners(collection?.collectionBannerList)
      // setSelectedCountry({
      //   value: countries[0]?.name,
      //   label: countries[0]?.name,
      //   id: countries[0]?.id
      // });
    }
    catch(error){
      console.log("error is",error)
    }
  }

  const getSuperCollection = async() =>{
    try{
      const res =  await API.get("module/super/collection");
      setSuperCollection(res.data)
    }
    catch(error){
      console.log("error is",error)
    }
   
  }

  useEffect(()=>{
     getSuperCollection();
     if(collectionId){
      getCollection()
     }
  },[])
 
  const handleValidation = () =>{
    const errors = {}
    if(!title){
      errors.title = "Title is required"
    }
    if(!titleArabic){
      errors.titleArabic = "Arabic Title is required"
    }
    if(!superCollectionId){
      errors.superCollectionId = "Please select a superCollection";
    }
    if(!seoUrl){
      errors.seoUrl = "Seo Url is required";
    }
    else if (/\s/.test(seoUrl)) {
      errors.seoUrl = "SEO URL cannot contain spaces";
  }
    if(Object.keys(errors).length > 0){
      setError(errors)
      return false
    }
    return true
  }

  const handleSaveCollection = async() =>{
    const isValidate = handleValidation()
    if(isValidate){  
      const updateProducts = products.map((product) => {
        return {
          productId: product.id,
          rank:product.rank
        };
      });
    const newPayload = {
      "id":collectionId ? collectionId : null,
      "title": title,
      "titleArabic":titleArabic,
      "status": collectionStatus == "Active" ? true : false,
      "image":imageurl,
      "superCollectionId":parseInt(superCollectionId),
      "ranks": updateProducts,
      "seoUrl":seoUrl,
      "countryWiseDetails":countryWiseDetails,
      "collectionBannerList":collectionBanners
      }
    try{
      showLoading()
      if(collectionId){
        const res = await API.put(`/api/v1/private/collection`,newPayload);
        if(res.status ==200){
          hideLoading()
          showSuccessNotification("Collection Updated Succefully")
          setTimeout(()=>{
            window.location.href ="/admin/dashboard/Collections"
            
          },1000)
        }
        else{
          hideLoading()
          showErrorNotification("Failed to update Collection")
        }
      }
      else{
        const res =await API.post(`/api/v1/private/collection`,newPayload);
        if(res.status == 200){
          hideLoading()
          showSuccessNotification("Collection Created Succefully")
          setTimeout(()=>{
            window.location.href ="/admin/dashboard/Collections"
            
          },1000)
        }
        else{
          hideLoading()
          showErrorNotification("Failed to create Collection")
        }
      }
      
    }
    catch(error){
      hideLoading()
      showErrorNotification(error.response.data)

    }
  }
  }
  const uploadImage = (imagefileupload) =>{
    const formData = new FormData();
      formData.append("file", imagefileupload);
      const config = {
        method: "POST",
        url: "/s3/file/upload",
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      }
      try {
       API.request(config).then(res => setImageUrl(res?.data?.s3Url));
  
      }
      catch (error) {
        console.log("when handling image", error);
      }
  }

  const handleImage = (event)=>{
    if (event.target.files && event.target.files.length) {
      const imageFile = event.target.files[0];
      uploadImage(imageFile)
    }
  }

const handleCountryDescriptionUpdate = (fieldName, value) => {
  const updatedCountryDetails = countryWiseDetails.map((detail) => {
    if (detail.countryId === selectedCountry.id) {
      return {
        ...detail,
        [fieldName]: value || '', // Ensure empty strings are saved as empty
      };
    }
    return detail;
  });
  setCountryWiseDetails(updatedCountryDetails);
};

  

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  
  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });

  const getDescription=()=>{
  const value=countryWiseDetails.find(detail => detail.countryId == selectedCountry.id)?.descriptionArabic || ""
  return value;
  }

  console.log("collectionBannerList",collectionBanners)
 
  return (
    <>
    <div>
        <div className="d-flex justify-content-start-0">
        <div style={{ alignSelf: "flex-start", marginRight: "1rem" }}>
          <Button
          onClick={() => { props.history.goBack(); }}
          color="inherit"
          size="large"
        >
          <ArrowBackIcon />
          </Button>
          <span
            className="mx-2 my-2"
            style={{ fontWeight: "600", fontSize: "1.25rem" }}
          >
            New Collection
          </span>
        
        </div> 
        <div className="col text-end"><button className="savebtn" onClick={handleSaveCollection}>Save</button></div>
        </div>
        <div className="fullpage">
        <div className="firstblockcollection">
          <Card body>
            <div className="alignrow">
          <div className="columnalignactive" style={{width:"100%"}}>
            <label for="title"><b>Title* </b></label>
            <div> <input id="title" type="text" name="title" className="inputfieldsize" value={title} onChange={(e)=>setTitle(e.target.value)}/>
            {error.title &&<span className="help-block">{error.title}</span>}
            </div>
           </div>
           <div className="columnalignactive" style={{width:"100%"}}>
            <label for="titleArabic"><b>Title (Arabic)* </b></label>
            <div> <input id="titleArabic" type="text" name="titleArabic" className="inputfieldsize" value={titleArabic} onChange={(e)=>setTitleArabic(e.target.value)}/>
            {error.titleArabic &&<span className="help-block">{error.titleArabic}</span>}
            </div>
           </div>
           </div>
          <br/>

          <div className='countries' style={{width:"235px",position:"relative",zIndex:"100"}}>
            <label for="country"><b> Select Country</b></label>
  
            <Select
  options={countries}
  value={selectedCountry}
  onChange={(selectedOption) => {
    // Update the selected country
    setSelectedCountry(selectedOption);

    // Check if the selected country exists in countryWiseDetails
    const existingCountryDetail = countryWiseDetails.find(
      (detail) => detail.countryId === selectedOption.id
    );

    if (!existingCountryDetail) {
      // Add a new entry for the selected country if it doesn't exist
      setCountryWiseDetails((prevDetails) => [
        ...prevDetails,
        {
          countryId: selectedOption.id,
          description: '',
          descriptionArabic: '',
        },
      ]);
    }
  }}
/>


          </div>
         
          <div className="alignrow">
            
          <div className="columnalignactive">
          <label for="description"><b>Description </b></label>
          
            <BodyTextEditor
             fieldName={"description"}
             value={countryWiseDetails.find(detail =>  detail.countryId === selectedCountry.id)?.description || ""}
             setValue={(value) => handleCountryDescriptionUpdate("description", value)}
            />
          </div>
          
          <div className="columnalignactive">
          <label for="descriptionArabic"><b>Description (Arabic) </b></label>
            <BodyTextEditor
            fieldName={"descriptionArabic"}
            value={getDescription()}
            setValue={(value) => handleCountryDescriptionUpdate("descriptionArabic", value)}
           
            />
          </div>
          </div>
          <br/>
          <div className="alignrow">
          <div className="columnalignactive" style={{width:"100%"}}>
          <label for="title"><b>Seo Url * </b></label>
          <div> <input id="seoUrl" type="text" name="seoUrl" className="inputfieldsize" value={seoUrl} onChange={(e)=>setSeoUrl(e.target.value)}  onKeyDown={(e) => {
        if (e.key === ' ') {
          e.preventDefault(); // Prevent space key from being entered
        }
      }}/>
            {error.seoUrl &&<span className="help-block">{error.seoUrl}</span>}
            </div>
            </div>
          </div>
        
       
          </Card>
          <Card body>
          <b> Collection Type*</b>
          <div className="columnalignactive">
              <div className="radiorow">
                {superCollection.map((superItem) => (
                  <React.Fragment key={superItem.id}>
                    <input type="radio" value={superItem?.id} checked={superItem.id == superCollectionId} name="collection" onChange={(e)=> setSuperCollectionId(e.target.value)}   />
                    {superItem.name}
                  </React.Fragment>
                ))}
              </div>
              {error.superCollectionId && <span className="help-block">{error.superCollectionId}</span>}
            </div>
          </Card>
          <div className="collectionBanner">
          <Card style={{ background: "#FFFFFF" }}>
        <Card.Body>
          <div className="row">
            <div style={{marginBottom:"10px"}} className="col">
              <p className="h6">Collection banner</p>
            </div>
          </div>
                <CollectionBanner collectionBanners={collectionBanners} setCollectioBanners={setCollectioBanners} collectionId={collectionId}/>
                </Card.Body>
                </Card>
          </div>
         
        </div> 
      
        <div className="secondblock">
        <Card body>
        <div><b>Collection Status*</b></div> <br/>
        <div className="columnalignactive">
          <select value={collectionStatus} onChange={(e)=>setCollectionStatus(e.target.value)}>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
        </Card>
        <Card body>
        <div> <b>Image</b></div><br />
        <div className="columnalignactive">
        <div className="imagecountryblock">
        {imageurl ? ( <div style={{alignItems:"center"}}><label for="img"> <div className="newvaraintbutton">Add Image</div></label><input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} /><br/> <img src={imageurl} className="imageshowsize" /></div>)
         : <div style={{alignItems:"center"}}> <label for="img"> <div className="newvaraintbutton">Add Image</div></label><input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} /></div>}
          {/* <label for="img" style={{alignItems:"center"}}> <div className="newvaraintbutton" >Add Image</div></label>
          <input type="file" name="uploadfile" onChange={handleImage} id="img" style={{ display: "none" }} />  <img src={imageurl} className="imageshowsize" /> */}
        </div>
        </div>
        </Card>
        </div>
        </div>
        <ProductSection products = {products} setProducts={setProducts} />
    </div>
   
    </>
  )
}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Collection);