import React, {useState, useEffect} from 'react'
import { connect } from "react-redux";
import DragIcon from "@mui/icons-material/DragIndicatorOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Card, Dropdown as ActionBar, Modal } from "react-bootstrap";
import ReactDragListView from "react-drag-listview/lib/index.js";
import DataTable from "react-data-table-component";
import { uploadFile } from '../../../services/fileUploadService';
import { checkImageRatio } from '../../../components/ImageUploadError/ImageUploadError';
const CollectionBanner = (props) => {

    const  {collectionBanners = [], setCollectioBanners, collectionId} = props

    const [collectionBanner,setCollectioBanner] = useState({});
    const [collectionBannerToDelete, setCollectionBannerToDelete] = useState({})
    const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
    const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [editables, setEditables] = useState(false);
    // const [sortColumn, setSortColumn] = useState("id");
    // const [sortDirection,setSortDirection]=useState("asc");


    const isNewBanner = !collectionBanner.id;

    const columns  = [
        {
          name: "",
          cell: () => <DragIcon className="drag-handle"></DragIcon>,
          width: "50px",
        },
        {
          name: "ID",
          selector: "id",
          sortable: true,
          width: "100px",
        },
        {
            name: "Image",
            selector: "imageUrl",
            maxWidth: "150px",
            cell: (point) => renderBannerImage(point),
          },
          {
            name: "Rank",
            selector: "rank",
            maxWidth: "100px",
            center: true,
            sortable: "true",
          },
       
        {
          name: "Actions",
          width: "90px",
          // maxWidth: "100px",
          center: true,
          allowOverflow: true,
          cell: (collection) => renderActionBar(collection),
        },
      ]


      const renderBannerImage = (collectionBanner) => (
        <img
          src={collectionBanner.imageUrl}
          className="form-image"
          alt="Points Icon"
          // width={100}
          height={50}
        />
      );

      const renderActionBar = (collection) => {
        return (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu
              popperConfig={{
                strategy: "fixed",
                onFirstUpdate: () => window.dispatchEvent(new CustomEvent("scroll")),
              }}
            >
              <ActionBar.Item
                onClick={() => {
                setCollectionBannerToDelete(collection.id);
                showConfirmDeleteModal();
                }
                }
              >
                <DeleteIcon className="icon-small" style={{ fill: "red" }} />
                <span style={{ color: "red" }}>Delete</span>
              </ActionBar.Item>
            </ActionBar.Menu>
          </ActionBar>
        )
    
      };

      const showAddEditModal = () =>{
        setIsAddEditModalVisible(true)
      }

      const hideAddEditModal = () =>{
        setIsAddEditModalVisible(false)
      }

      const handleAddNew = () =>{
        if(collectionBanners.length > 2){
          showErrorNotification("You can't add more than 3 banners.")
          return
        }
        showAddEditModal()
        setCollectioBanner({})
      }

      const hideConfirmDeleteModal = ()=>{
        setIsConfirmDeleteModalVisible(false)
      }
      const showConfirmDeleteModal = ()=>{
        setIsConfirmDeleteModalVisible(true)
      }

      const handleImageFileSelect = async(e) =>{
        if (e.target.files && e.target.files.length){
            const imageFile = e.target.files[0];
            const errors = {};
            if (imageFile && imageFile.size && imageFile.size > 81920){
              errors.bannerImage = "Image should be less than 80 Kb!";
              setErrors(errors)
              return Object.keys(errors).length === 0 ? null : errors;
            } 

             const imageRatioValid = await checkImageRatio(imageFile, 2, 1); // Corrected the ratio in the error message
                  if (!imageRatioValid) {
                    errors.bannerImage = "Image Ratio should be 2:1";
                    this.setState(errors);
                    return Object.keys(errors).length === 0 ? null : errors;
                  }
            try{
                showLoading();
            // Upload the image file
            const { data: uploadDetails } = await uploadFile(imageFile);
                console.log("uploadDetails",uploadDetails)
                setCollectioBanner({...collectionBanner, imageUrl:uploadDetails?.s3Url})
            }
            catch(error){
                console.log("Error",error)
                showErrorNotification(
                    "Error on uploading image file! " + (error.message ?? "")
                  );
            }
            finally{
                hideLoading();
            }
        }
      }

      const renderPencilIcon = () =>{

      }

      const validate = () => {
        const errors = {};
        const  { imageUrl, redirectionLink} =  collectionBanner;
        if (!imageUrl)
            errors.bannerImage = "Image is a required field!";
          setErrors(errors);
          return Object.keys(errors).length === 0 ? null : errors;
        }

        const createBanner = () =>{
            const ids = collectionBanners.length > 0 ? collectionBanners.map((it) => it.id) : null;
            collectionBanner.id = ids ? Math.max(...ids) + 1 : 1;

            const ranks =
            collectionBanners.length > 0
              ? collectionBanners.filter((it) => it.rank).map((it) => it.rank)
              : null;
          collectionBanner.rank = ranks ? Math.max(...ranks) + 1 : 1;
          // collectionBanner.isActive = true;
          // collectionBanner.is_new = true;
          collectionBanner.collectionId = collectionId

          setCollectioBanners([...collectionBanners, collectionBanner])
          showSuccessNotification("Banner Created Successfully!");
          onBannersUpdatedLocally()
        }

        const updateBanner = (id) => {
            const newBanners = collectionBanners.map((it) => (it.id === id ? collectionBanner : it));
            setCollectioBanners(newBanners);
            showSuccessNotification("Banner Updated Successfully!");
            onBannersUpdatedLocally()
          };
        
        const deleteBanner = (id) =>{
          console.log("id+++++",id)
          const bannerToDelete = collectionBanners.find((it) => it.id == id) || {};
          if (!bannerToDelete) {
            console.log("deleteBanner:: Banner not found with id=", id);
            this.showErrorNotification("Banner not found!");
            return;
          }
        const newBanners = collectionBanners
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > bannerToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
      setCollectioBanners(newBanners);
      showSuccessNotification("Banner Deleted Successfully!");
            onBannersUpdatedLocally()
          }

      const handleSubmit = (e) =>{
        e.preventDefault();

        const errors = validate()
        console.log("Error: " ,errors)
        if (errors) {
            showErrorNotification("There are some invalid form fields!");
            return;
            }
        if(collectionBanner.id === null || collectionBanner.id === undefined){
            createBanner()
        }
        else{
            updateBanner(collectionBanner.id)
        }
        hideAddEditModal()
      }

      const  clearForm = () => {
        Array.from(document.querySelectorAll("input")).forEach(
          (it) => (it.value = "")
        );
        Array.from(document.querySelectorAll("textarea")).forEach(
          (it) => (it.value = "")
        );
        setErrors({});
        setEditables({});
        setCollectioBanner({})
      };

      const onBannersUpdatedLocally = () =>{
        clearForm();

      }

      const dragProps = {
        onDragEnd(fromIndex, toIndex) {
          updateRank(fromIndex, toIndex);
        },
        nodeSelector: ".rdt_TableRow",
        handleSelector: ".drag-handle",
        enableScroll: true,
      }

      const updateRank = async (fromIndex, toIndex) => {
        const updateCollectionBanners = [...collectionBanners];
        const movedProduct = updateCollectionBanners.splice(fromIndex, 1)[0];
        updateCollectionBanners.splice(toIndex, 0, movedProduct);
      
        updateCollectionBanners.forEach((banner, index) => {
          banner.rank = index + 1;
        });
      
      
        setCollectioBanners(updateCollectionBanners);
      };

      const form = (
        <>
          <div className="row mt-2">
            <div className="form-group col-6">
              <label htmlFor="bannerImage">Image*</label>
              <div className="input-group">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageFileSelect}
                  name="bannerImage"
                  id="bannerImage"
                  className="form-control"
                  disabled={!isNewBanner && !editables.bannerImage}
                />
                {renderPencilIcon("bannerImage")}
              </div>
              <span className="help-block">{errors.bannerImage}</span>
            </div>
            {collectionBanner?.imageUrl && (
              <div className="form-group col-6">
                <label htmlFor="selectedBannerImage">Selected Image</label>
                <div>
                  <img
                    id="selectedBannerImage"
                    name="selectedBannerImage"
                    src={collectionBanner.imageUrl}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              
            )}
            <br></br>
             <span className="" style={{color:"green"}}> <ul>
                <li>Image Ratio should be 2:1</li>
                <li>Recommended 1200 x 600 Resolution</li>
                <li>size upto 80kb</li>
              </ul></span>
          </div>
        </>
      );

      const showLoading = () => props.toggleLoading({ isLoading: true });
  
      const hideLoading = () => props.toggleLoading({ isLoading: false });
    
      const showSuccessNotification = (notificationMessage) =>
        showNotification(notificationMessage, true);
    
      const showErrorNotification = (notificationMessage) =>
        showNotification(notificationMessage, false);
    
      const showNotification = (notificationMessage, isSuccessMessage) =>
        props.showNotificationMessage({
          notificationMessage,
          successMessage: isSuccessMessage,
          showNotification: true,
        });

    return (
    <div className="container px-0">
      <div className="row">
      <div className="form-group col">
      <Card>
      <ReactDragListView {...dragProps}>
      <DataTable
                  highlightOnHover
                  columns={columns}
                  data={collectionBanners ?? []}
                //   onSort={(sortColumn, sortDirection) => {

                //     setSortColumn(sortColumn?.selector);
                //     setSortDirection(sortDirection);
                //   }
                //   }
                //   pagination
                  responsive
                //   defaultSortField={sortColumn}
                //   sortIcon={<ArrowDownward></ArrowDownward>}
                />
      </ReactDragListView>
        <button
                onClick={handleAddNew}
                className="greenbackground"
              >
                Add New
              </button>
              </Card>
        </div>
        </div>

        <Modal
          show={isAddEditModalVisible}
          onHide={hideAddEditModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {collectionBanner.id === null || collectionBanner.id === undefined
                ? "Add Banner"
                : "Edit Banner"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              type="button" style={{borderRadius:"20px"}}
              onClick={hideAddEditModal}
              className="btn btn-sm btn-outline-secondary px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="greenbackground"
            >
              {collectionBanner.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isConfirmDeleteModalVisible}
          onHide={hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                deleteBanner(collectionBannerToDelete);
                hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
    </div>
  )
}
function mapStateToProps(state) {
    return {};
  }
function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CollectionBanner);